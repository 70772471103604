<template>
  <div id="about-wrapper">
    <h1>about that</h1>
  </div>
</template>

<script>
export default {
  name: 'AboutView'
}
</script>