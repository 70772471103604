<!-- header vue component -->
<template>
  <!-- header wrapper -->
  <div class="header-wrapper">
    <!-- header image -->
    <div class="header-image-wrapper" @click="nav('/')">
      <img class="header-image" src="@/assets/proto_logo.png" />
    </div>
    <!-- header nav wrapper -->
    <font-awesome-icon :icon="['fas', 'bars']" class="header-mobile-button" @click="headerMobileOpen = !headerMobileOpen" />
    <div :class="{'header-button-wrapper': true, 'header-mobile-open': headerMobileOpen}">
      <nav-button @click="nav('about')">What is it?</nav-button>
      <nav-button @click="nav('examples')">Examples</nav-button>
      <nav-button @click="nav('aboutus')">About Us</nav-button>
      <nav-button @click="nav('setup')">Setup Guide</nav-button>
      <nav-button @click="nav('contact')">Contact</nav-button>
      <div class="header-spacer"></div>
      <nav-button type="primary">Sign in</nav-button>
      <nav-button type="sunken">Register</nav-button>
      <div class="header-spacer"></div>
      <nav-button type="raised">Build!</nav-button>
    </div>
  </div>
</template>

<!-- header script -->
<script>
// import vue components
import NavButton from './NavButton.vue';

// fa imports
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);


// export vue component
export default {
  components: { NavButton, FontAwesomeIcon },
  name: "HeaderSection",
  data () {
    return {
      headerMobileOpen: false
    }
  },
  methods: {
    nav (dest) {
      this.$router.push(dest);
    }
  }
}
</script>

<!-- header css -->
<style>
.header-wrapper {
  width: calc(100vw - 50px);
  height: calc(var(--header-height) - 100px);

  border-bottom: 1px solid var(--color-brand-tertiary);

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

.header-button-wrapper {
  display: flex;
}

.header-image {
  filter: invert(1);
  height: var(--header-image-height);
  margin: 12px;
}

.header-image-wrapper {
  background-color: var(--color-brand-primary);
  border: 1px solid var(--color-brand-secondary);
  border-radius: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.header-spacer {
  width: 8px;
}

.header-mobile-button {
  display: none;
}

/* mobile stylings */
@media screen and (max-device-width: 1000px) {
  .header-mobile-button {
    display: block;
    font-size: 2.5rem;
    cursor: pointer;
  }

  .header-mobile-button:hover {
    background-color: rgba(0, 0, 0, .05);
  }

  .header-button-wrapper {
    background-color: var(--color-brand-primary);
    flex-direction: column;
    overflow: hidden;

    transition: max-height .2s ease;

    position: absolute;
    top: calc(var(--header-height));    
    left: 0px;
    width: 100vw;
    max-height: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .header-button-wrapper.header-mobile-open {
    max-height: 70vh;
  }
}
  </style>