<!-- app landing page -->
<template>
  <!-- landing page wrapper el -->
  <div id="landing-wrapper">
    <!-- landing page header -->
    <header-section v-if="false"></header-section>
    <div class="landing-banner">
      <img id="landing-banner-image" alt="A stock splash image of a phone." src="@/assets/banner.png" />
    </div>
    <div class="landing-content">
      <content-box title="Title" v-for="i in 6" :key="i">
        Body text for whatever you'd like to say. Add main takeaway points,
        quotes, anecdotes, or even a very very short story.
      </content-box>
    </div>
    <footer-section v-if="false"></footer-section>
  </div>
</template>

<!-- landing page script -->
<script>
// import vue components
import HeaderSection from '@/components/HeaderSection.vue';
import ContentBox from '@/components/ContentBox.vue';
import FooterSection from '@/components/FooterSection.vue';

// export vue component
export default {
  components: { HeaderSection, ContentBox, FooterSection },
  name: 'LandingPage'
}
</script>

<!-- landing page css -->
<style>
/* landing ids */
#landing-wrapper {
  background-color: var(--page-background-color);
  min-height: 100vh;

  overflow: hidden;
}

/* landing classes */
.landing-banner {
  background-color: var(--color-brand-tertiary);
  display: flex;
  justify-content: center;
  align-items: center;

  width: calc(100vw - 128px);
  padding: 64px;
}

.landing-content {
  display: flex;
  flex-wrap: wrap;
}
</style>