<!-- content box vue component -->
 <template>
  <div class="content-box-wrapper">
    <h1><font-awesome-icon :icon="['fas', 'circle-exclamation']" /></h1>
    <div class="content-box-content">
      <h1>{{ title }}</h1>
      <slot></slot>
    </div>
  </div>
</template>

<!-- content box script -->
<script>
// fa imports
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab);

// export vue component
export default {
  name: "ContentBox",
  // vue props
  // title: the title that will appear in the content box
  props: ["title"],
  components: {FontAwesomeIcon}
}
</script>

<!-- content box css -->
<style scoped>
.content-box-wrapper {
  display: flex;
  align-items: flex-start;
  padding: 64px;
  max-width: calc((100vw / 4) - 128px - 24px);
}

.content-box-content {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

/* mobile stylings */
@media screen and (max-device-width: 1000px) {
  .content-box-wrapper {
    padding: 32px;
    max-width: calc(100vw - 64px - 24px);
  }
}
</style>