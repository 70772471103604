<template>
  <div id="examples-wrapper">
    <h1>examples</h1>
  </div>
</template>

<script>
export default {
  name: 'ExamplesView'
}
</script>